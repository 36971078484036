

































































































import { Component, Vue } from "vue-property-decorator";

import VUserDialog from "@/iam/components/VUserDialog.vue";
import { AppSection, UserModel } from "@/shared/models";
import { PermissionEntity } from "@/iam/models/PermissionModel";

@Component({
  components: { VUserDialog },
})
export default class UsersView extends Vue {
  readonly headers = [
    {
      text: this.$lang("users.userName"),
      align: "start",
      value: "fullName",
    },
    {
      text: this.$lang("users.components.username"),
      align: "start",
      value: "username",
    },
  ];
  userSearch = "";
  userDialog = false;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get users(): Array<UserModel> {
    return this.$store.state.usersStore.users;
  }

  get isLoading(): boolean {
    return (
      this.$store.state.usersStore.loadingUsers ||
      this.$store.state.usersStore.isEntityNamesLoading
    );
  }

  get isUsersPage(): boolean {
    return this.$route.name === AppSection.USERS;
  }

  created() {
    document.title = this.$lang("documentTitle", this.$lang("users.title"));

    this.$store.dispatch("fetchUsers");
    this.$store.dispatch("getPermissionFeatures", [
      PermissionEntity.APP_SECTION,
      PermissionEntity.APP_SECTIONS,
    ]);
  }

  isSelectedUser({ username }: UserModel): boolean {
    return username === this.$route.params.user;
  }

  selectUser({ username }: UserModel) {
    this.$router.push({
      name: "user",
      params: {
        user: username,
      },
    });
  }
}
