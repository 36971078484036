
































































import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";

import { CurrentUserModel } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component
export default class VUserDialog extends Vue {
  @Prop() value: CurrentUserModel | null = null;
  @Prop() dialog = false;

  @Ref("form") readonly form!: VueForm;

  localValue = new CurrentUserModel();
  isEdit = false;

  isValid = true;

  get title() {
    return this.isEdit ? "users.editTitle" : "users.newTitle";
  }

  get dispatch() {
    return this.isEdit ? "updateUser" : "createUser";
  }

  get rules() {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.maxLength(255, this.$lang("validation.maxLength", 255)),
    ];
  }

  get rulesUsername() {
    const rules: Array<any> = [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.maxLength(255, this.$lang("validation.maxLength", 255)),
      ValidUtil.email(this.$lang("validation.email")),
    ];
    if (!this.isEdit) {
      rules.push(
        ValidUtil.unique(
          this.$store.getters.getUsernames,
          this.$lang("validation.unique")
        )
      );
    }
    return rules;
  }

  get isSuperAdmin() {
    return this.localValue.isSuperUser;
  }

  get isSavingInProgress(): boolean {
    return this.$store.state.usersStore.isUserSaving;
  }

  @Watch("value", { immediate: true })
  watchValue(value: CurrentUserModel | null) {
    this.isEdit = value !== null;
    this.localValue = value
      ? CurrentUserModel.of(value)
      : new CurrentUserModel();
  }

  @Watch("dialog")
  watchDialog(value: boolean) {
    if (value) {
      this.watchValue(this.value);
    }
  }

  async save() {
    if (!this.form.validate()) {
      return;
    }

    await this.$store.dispatch(this.dispatch, this.localValue);

    if (this.dispatch === "createUser") {
      this.$router.push({
        name: "user",
        params: {
          user: this.localValue.username,
        },
      });
    }

    this.close();
  }

  close() {
    this.$emit("input:close", false);
  }
}
